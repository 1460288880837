@import "vars.css";

.header {
    position: sticky;
    top: var(--header-height);
    background-color: #fff;
    z-index: 5;
    padding: 1rem 1rem 0 1rem;
}

.clothesConfigurationPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.pageTitleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.pageTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    margin: 0;
}

th.propertyTitle {
    font-size: 1rem;
    font-weight: bold;
    color: #2b2b2b;
    font-family: sans-serif;
}

.pageOptions {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: solid 1px rgb(144, 141, 141);
    margin-top: .6rem;
    margin-bottom: .6rem;
    text-transform: capitalize;
    overflow-x: auto;
}

.pageOptionsEmpty {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0;
}

.pageOption {
    color: #DA8C96;
    font-weight: bold;
    font-size: 1rem;
    text-transform: capitalize;
    text-wrap: none;
    word-break: keep-all;
    white-space: nowrap;
    margin-right: .6rem;
}

.pageOptionSelected, .pageOption:hover {
    color: #e0576b;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 60%;
    cursor: pointer;
}

.pageTable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-transform: capitalize;
}

.algoTable {
    width: 100%;
    height: 100%;
}

.algoTable * {
    font-family: "Poppins", sans-serif !important;
}

.innerTable {
    min-width: 40rem;
}