.messagesWrapper {
    position: absolute;

    width: 100%;
    height: calc(100vh - var(--header-height));

    padding: 1.4rem 2rem;

    background-color: #fff;
}

.messagesWrapper > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.messagesWrapper header h1 {
    margin: 0;
    color: #e0576b;
    font-weight: bold;

    justify-self: center;
    align-self: center;
}

.messagesWrapper .header .select {
    padding: .2rem .6rem .3rem .6rem;

    color: #777;
    border: 2px solid #bbb;
    border-radius: 6px;

    font-family: inherit;
    font-size: 1.1rem;
    font-weight: bold;

    width: fit-content;
    margin: 0;

    justify-self: center;
    align-self: center;

    outline: none;
}

.messagesWrapper .messages {
    overflow-y: auto;
    height: 85%;
    padding-top: 1rem;
}

.messagesWrapper .message {
    position: relative;

    margin: 0 0 1rem;
    padding: 2vh max(.6rem, 1vw);

    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 0 1px 0 #0001;
}

.messagesWrapper .message header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vh;
}

.messagesWrapper .message header .infos {
    align-self: flex-start !important;
}

.messagesWrapper .message header h2 {
    display: inline-block;
    margin: 0;
}

.messagesWrapper .message .header .select {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
}

.messagesWrapper .message header option {
    color: #e0e0e0;
}

.messagesWrapper .message header .status {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-left: .2rem;

    font-weight: bold;
}

.messagesWrapper .message header .status .statusText {
    margin-left: .2rem;
}

.messagesWrapper .status.active {
    color: #0085ff;
}

.messagesWrapper .status.finished {
    color: #32E182;
}

.messagesWrapper .status.draft {
    color: #a6a6a6;
}

.messagesWrapper .message header .status FontAwesomeIcon {
    margin-right: .2rem;
}

.messagesWrapper .message p {
    text-align: justify;
    overflow-wrap: break-word;
}

.messagesWrapper .message .date {
    position: absolute;
    bottom: .4rem;
    right: .8rem;
    font-weight: bold;
    color: #aaa;
}

.messagesWrapper button {
    display: inline-flex;
    justify-content: center !important;
    align-items: center !important;

    min-height: 2rem;
    min-width: 2rem;

    padding-inline: .6rem;

    border: none;
    border-radius: 6px !important;

    justify-self: center;
    align-self: center;

    font-family: inherit;
    font-weight: bold;
    font-size: 1.1rem !important;

    cursor: pointer;
    outline: none;
}

.messagesWrapper button .buttonText {
    margin-left: .4rem;
}

.messagesWrapper button FontAwesomeIcon {
    font-size: 1.2rem;
}

.messagesWrapper i.pen {
    margin-right: -.2rem;
    margin-top: -0.2rem;
}

.messagesWrapper .primaryButton {
    color: #fff;
    background-color: #e0576b;
    box-shadow: 0 0 0 3px #e0576b40;
}

.messagesWrapper .secondaryButton {
    color: #e0576b;
    background-color: #fff;
    box-shadow: 0 0 0 3px #e0576b40;
}

.messagesWrapper .cancelButton {
    background-color: #ff4545;
    box-shadow: 0 0 0 3px #e0576b40;
    color: #fff;
}

.messagesWrapper .dates {
    color: #0085ff;
    font-weight: bold;
}

.messageModal label {
    color: #e0576b;
    margin-bottom: 1vh;
}

.messageModal input, .messageModal textarea, .messageModal select {
    font-family: inherit;
    border: 1px solid #0002;
    padding: .4rem .6rem;
    color: #000B;
    font-weight: bold;
    border-radius: 6px;
    outline: none;
    transition: box-shadow .2s ease;
    margin-bottom: 4vh;
    resize: none;
}

.messageModal textarea {
    height: 10rem;
}

.messageModal input:focus, .messageModal textarea:focus, .messageModal select:focus {
    box-shadow: 0 0 0 3px #e0576bAA;
    border-color: transparent;
}

.messageModal input[type="button"] {
    background-color: #e0576b;
    color: #fff;
    box-shadow: 0 0 0 2px #e0576b60;
    border: none;
    transition: color .1s ease, background-color .1s ease;
}

.messageModal input[type="button"]:hover {
    background-color: #fff;
    color: #e0576b;
}

.messageModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.messagesWrapper .btnRemove {
    background-color: #e0576b;
    color: #fff;
    box-shadow: 0 0 0 2px #e0576b60;
    transition: color .1s ease, background-color .1s ease;
}

.messagesWrapper .btnRemove:hover {
    background-color: #fff;
    color: #e0576b;
}

.messagesWrapper .promoCode {
    font-weight: bold;
}

.messagesWrapper .promoCode .code {
    color: #0085ff;
}

.messagesWrapper .promoCode .noCode {
    color: #666;
    font-weight: normal;
}

.messagesWrapper .messageLabel {
    font-weight: bold;
    font-size: 1.1rem;
}

.messagesWrapper .messageInfo {
    display: block;
}

.quitModal {
    background: none;
    border: none;
    font-size: 1.6rem;
    color: #444;
}

.messageModal fieldset > * {
    display: block;
}

.messageModal fieldset input {
    margin: 0 0 1.2rem;
}

.modalTitle {
    font-weight: bold;
    color: #e0576b;
}

.messageModal fieldset label {
    margin: 0;
}

.messageModal label.disabled {
    filter: grayscale(1);
    opacity: .6;
}

.messagesWrapper .message .buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

@media screen and (max-width: 900px) {

    .messagesWrapper .infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .messagesWrapper .message header {
        margin-bottom: 1vh;
    }
}

@media screen and (max-width: 720px) {
    .messagesWrapper .buttonText {
        display: none;
    }
}

@media screen and (max-width: 550px) {

    .messagesWrapper button {
        font-size: 1.4rem !important;
        padding: .6rem;
    }

    .messagesWrapper {
        height: calc(100vh - var(--header-height));
    }

    .messagesWrapper > header {
        flex-wrap: wrap;
    }

    .messagesWrapper .message header {
        width: 100% !important;
        padding: 0;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    .messagesWrapper .message header .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 2rem;
    }

    .messagesWrapper .message header .infos {
        text-align: center;
        width: 100% !important;
    }

    .messagesWrapper .message header .infos span {
        margin: .5vh auto;
    }

    .messagesWrapper .message {
        position: relative;
        margin-top: 1vh;
    }

    .messagesWrapper .message header {
        padding-top: 1vh;
    }
}