@import "styles/vars.css";

@font-face {
    font-family: "Poppins";
    src: url('/src/fonts/poppins-v19-latin-regular.eot');
    src: url('/src/fonts/poppins-v19-latin-regular.woff') format('woff'), url('/src/fonts/poppins-v19-latin-regular.ttf') format('truetype'), url('/src/fonts/poppins-v19-latin-regular.svg') format('svg');
}

*, *::before, *::after {
    box-sizing: border-box;
    word-break: break-word;
    text-wrap: balance;
}

body {
    font-family: Poppins, serif;
    font-weight: normal;
    min-height: 100vh;
}

.App {
    height: 500px;
}

.beatLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
}