@import "vars.css";

.header {
    position: sticky;
    top: 0;
    left: 0;
    height: var(--header-height);
    z-index: 99;
}

.container {
    min-height: 100%;
    max-width: 100%;
    width: 100%;
    background-color: #f8f9fa;
}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;

    width: 100%;
    height: var(--header-height);
    padding: 0;
}

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}

.navbar a {
    text-decoration: none;
}

a:active {
    color: black;
}

a:hover {
    font-weight: bold;
}

.btn {
    display: none;
}

.logo {
    height: calc(var(--header-height) * 2 / 3);
}

.blackScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #0008;
    opacity: 0;
    pointer-events: none;
    transition: opacity .1s ease;
}

.blackScreen.visible {
    opacity: 1;
    pointer-events: all;
    z-index: 90;
}

.navbarLinks {
    display: flex;
    justify-content: space-around;
    gap: 5rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: 0!important;
}

@media screen and (max-width: 1000px) {
    .navbarLinks {
        gap: 1rem;
        padding: 1rem 0;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .nav-bar {
        flex-direction: column;
    }

    .logo {
        display: block;
    }

    .btn {
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        color: black;
        cursor: pointer;
        border: none;
        background-color: white;
    }

    .btn:hover {
        color: rgb(144, 141, 141);
    }
}