@import "vars.css";

.configurationPage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: calc(100vh - var(--header-height));
}

.leftMenu {
    position: sticky;
    top: var(--header-height);
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - var(--header-height));
    width: 15rem;
    background-color: white;
    border-right: .1rem solid #c3c3c3;
}

.titleLogo {
    height: 3.4rem;
    width: 3.4rem;
    padding: .2rem;
    background-color: white;
    border-radius: 5rem;
}

.menuTitle {
    display: flex;
    height: 5rem;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #e0576b;
    padding-left: 1rem;
    gap: .8rem;
}

.menuTitleText {
    font-weight: bold;
    color: white;
    font-size: 1.2rem;
}

.menuOption {
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: #2b2b2b;
    border-bottom: solid .1rem #c3c3c3;
    padding-left: 1rem;
    gap: .8rem;
}

.menuOptionIcon {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 5rem;
}

.menuOption:not(.menuOptionSelected):hover {
    cursor: pointer;
    color: #fff;
    background-color: rgba(241, 131, 144, 0.73);
    border-color: rgba(241, 131, 144, 0.73);
}

.menuOptionSelected {
    color: #fff;
    background-color: #f18390;
}

.menuOptionText {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0;
    text-transform: capitalize;
}

.configurationMenu {
    width: calc(100% - 5rem);
    height: 100%;
}

@media screen and (max-width: 1000px) {

    .menuOptionText {
        display: none;
    }

    .menuTitleText {
        display: none;
    }

    .leftMenu {
        width: fit-content;
        overflow-y: auto;
        min-width: 5rem;
        z-index: 80;
    }

    .menuOption, .menuTitle {
        padding: 0 .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}