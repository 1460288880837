.contestsWrapper {
    position: absolute;

    width: 100%;
    height: calc(100vh - var(--header-height));

    padding: 1.4rem 2rem;

    background-color: #fff;
}

.contestsWrapper > header {
    display: grid;
    grid-template-columns: auto 1fr auto;
}

.contestsWrapper > .header h1 {
    margin: 0;
    color: #e0576b;
    font-weight: bold;

    justify-self: center;
    align-self: center;
}

.contestsWrapper > .header .select {
    padding: .2rem .6rem .3rem .6rem;

    color: #777;
    border: 2px solid #bbb;
    border-radius: 6px;

    font-family: inherit;
    font-size: 1.1rem;
    font-weight: bold;

    width: fit-content;
    margin: 0;

    justify-self: center;
    align-self: center;

    outline: none;
}

.contestsWrapper .contests {
    overflow-y: auto;
    height: 85%;
    padding-top: 1rem;
}

.contestsWrapper .contest {
    margin: 0 0 1rem;
    padding: 2vh max(.6rem, 1vw);

    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 0 1px 0 #0001;
}

.contestsWrapper .contest header {
    margin-bottom: 2vh;
    display: flex;
    justify-content: space-between;
}

.contestsWrapper .contest header h2 {
    display: inline-block;
    margin: 0;
}

.contestsWrapper .contest header option {
    color: #e0e0e0;
}

.contestsWrapper .contest header .status {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-left: .2rem;

    font-weight: bold;
}

.contestsWrapper .contest header .status .statusText {
    margin-left: .2rem;
}

.contestsWrapper .status.active {
    color: #0085ff;
}

.contestsWrapper .status.finished {
    color: #32E182;
}

.contestsWrapper .status.draft {
    color: #a6a6a6;
}

.contestsWrapper .contest header .status FontAwesomeIcon {
    margin-right: .2rem;
}

.contestsWrapper .contest p {
    text-align: justify;
    overflow-wrap: break-word;
}

.contestsWrapper button {
    display: inline-flex;
    justify-content: center !important;
    align-items: center !important;

    min-height: 2rem;
    min-width: 2rem;

    padding-inline: .6rem !important;
    padding-block: .4rem;

    border: none;
    border-radius: 6px !important;

    justify-self: center;
    align-self: center;

    font-family: inherit;
    font-weight: bold;
    font-size: 1.1rem !important;

    cursor: pointer;
    outline: none;
}

.contestsWrapper button .buttonText {
    margin-left: .4rem;
}

.contestsWrapper button FontAwesomeIcon {
    font-size: 1.2rem;
}

.contestsWrapper i.pen {
    margin-right: -.2rem;
    margin-top: -0.2rem;
}

.contestsWrapper .primaryButton {
    color: #fff;
    background-color: #e0576b;
    box-shadow: 0 0 0 3px #e0576b40;
}

.contestsWrapper .secondaryButton {
    color: #e0576b;
    background-color: #fff;
    box-shadow: 0 0 0 3px #e0576b40;
}

.contestsWrapper .cancelButton {
    background-color: #ff4545;
    box-shadow: 0 0 0 3px #e0576b40;
    color: #fff;
}

.contestsWrapper .dates {
    color: #0085ff;
    font-weight: bold;
}

.contestModal label {
    color: #e0576b;
    margin-bottom: 1vh;
}

.contestModal input, textarea {
    font-family: inherit;
    border: 1px solid #0002;
    padding: .4rem .6rem;
    color: #000B;
    font-weight: bold;
    border-radius: 6px;
    outline: none;
    transition: box-shadow .2s ease;
    margin-bottom: 4vh;
}

.contestModal input:focus, textarea:focus {
    box-shadow: 0 0 0 3px #e0576bAA;
    border-color: transparent;
}

.contestModal input[type="button"] {
    background-color: #e0576b;
    color: #fff;
    box-shadow: 0 0 0 2px #e0576b60;
    border: none;
    transition: color .1s ease, background-color .1s ease;
}

.contestModal input[type="button"]:hover {
    background-color: #fff;
    color: #e0576b;
}

.contestModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.modalTitle {
    font-weight: bold;
    color: #e0576b;
}

.quitModal {
    background: none;
    border: none;
    font-size: 1.6rem;
    color: #444;
}

.contestsWrapper .btnRemove {
    background-color: #e0576b;
    color: #fff;
    box-shadow: 0 0 0 2px #e0576b60;
    transition: color .1s ease, background-color .1s ease;
}

.contestsWrapper .btnRemove:hover {
    background-color: #fff;
    color: #e0576b;
}

.contestsWrapper .contest header .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 900px) {
    .contestsWrapper > .header {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: .6rem 0;
    }

    .contestsWrapper > .header h1 {
        grid-column: 1;
        grid-row: 1;
        justify-self: start;
    }

    .contestsWrapper > .header .primaryButton {
        grid-row: 2;
        grid-column: 2;
        justify-self: end;
    }

    .contestsWrapper > .header .select {
        grid-row: 2;
        grid-column: 1;
        justify-self: start;
    }

    .contestsWrapper .contest header {
        margin-bottom: 1vh;
        display: block;
    }

    .contestsWrapper .contest header .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .contestsWrapper .contest header .buttons {
        gap: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .contestsWrapper button {
        padding: .6rem 2rem;
    }

    .contestsWrapper .buttonText {
        display: none;
    }

    .contestsWrapper button .buttonIcon {
        font-size: 1.6rem;
    }

    .contestsWrapper {
        height: calc(100vh - var(--header-height));
    }

    .contestsWrapper .contest header {
        width: 100% !important;
        margin-bottom: 2rem;
    }

    .contestsWrapper .contest header .infos {
        text-align: center;
        width: 100% !important;
    }

    .contestsWrapper .contest header h2 {
        width: 100%;
    }

    .contestsWrapper .contest header .infos span {
        margin: .5vh auto;
    }

    .contestsWrapper .contest {
        position: relative;
        margin-top: 1vh;
    }

    .contestsWrapper .contest header {
        padding-top: 1vh;
    }
}