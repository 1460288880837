@import "vars.css";

.loginContainer {
    color: #494049;
    display: grid;
    grid-template-columns: 40rem 1fr;
    background-color: #E3A4A4;
    width: 100%;
    min-height: calc(100vh - var(--header-height))
}

.title {
    margin: 0;
    font-weight: bold;
}

.sousTitle {
    font-weight: normal;
    margin: 0 0 3vh 0;
    font-size: 1.2rem;
}

.loginForm {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-self: center;
    align-self: center;
}

.loginForm input {
    width: 100%;
    margin-bottom: 1rem;
    padding: .6em .8em;
    background-color: #E8DCDC;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
}

.loginForm label {
    font-size: 1.2rem;
    font-weight: bold;
    padding: .1em .2em;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.passForget {
    position: relative;
    font-weight: bold;
    color: #494049;
    text-decoration: none;
    font-size: 0.8em;
    margin-bottom: 1vh;
}

.passForget::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #494049;
    width: 0;
    transition: all .1s ease-in-out;
    border-radius: 10px;
}

.passForget:hover {
    color: #494049;
}

.passForget:hover::after {
    width: 100%;
}

.divPassForget {
    display: flex;
    justify-content: flex-end;
}

.loginBtn {
    background-color: #746373;
    border: none;
    padding: 1.2rem;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 1rem;
}

@media screen and (max-width: 800px) {
    .title {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    .sousTitle {
        font-size: 2rem;
        font-weight: normal;
    }

    .loginContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #E3A4A4DD;
        overflow: hidden;
    }

    .loginForm {
        width: 100%;
        padding: 2rem;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        z-index: -1;
    }

    .imageSrc {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
    }

    .title {
        text-align: center;
    }

    .sousTitle {
        text-align: center;
    }

    .loginForm input, .loginForm button {
        width: 100%;
    }
}