.notFoundError {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notFoundError .errorTitle {
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
}

.notFoundError .errorDesc {
    font-size: 1.4rem;
    text-align: center;
}